import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./page6.css";
import Cookies from "js-cookie";
import { usercontext } from "../../usercontext";
import Navbar from "../Navbar/Navbar";
import { frontend_host } from "../../config";
import { Button } from '@mui/material'

export const TargetUserView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state ? location.state.user : null;
  const [allLockers, setLockers] = useState([]);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { curruser, setUser } = useContext(usercontext);



  useEffect(() => {
    if (!curruser) {
      navigate('/');
      return;
    }
  }, []);

  const handleNewLockerClick = () => {
    navigate('/create-locker');
  };


  const handleLockersClick = (lockers) => {
    navigate('/target-locker-view', { state: { locker: lockers, user: user } });
  }

  useEffect(() => {
    const fetchLockers = async () => {
      try {
        const token = Cookies.get('authToken');
        const params = new URLSearchParams({ username: user ? user.username : '' });

        console.log('Fetching lockers with token:', token);
        console.log('Fetching lockers with params:', params.toString());
        console.log('User object:', user);

        const response = await fetch(`host/get-lockers-user/?${params}`.replace(/host/, frontend_host), {
          method: 'GET',
          headers: {
            'Authorization': `Basic ${token}`,
            'Content-Type': 'application/json'
          }
        });

        console.log('Response status:', response.status);

        if (!response.ok) {
          const errorData = await response.json();
          setError(errorData.error || 'Failed to fetch lockers');
          console.error('Error fetching lockers:', errorData);
          return;
        }

        const data = await response.json();
        console.log('Response data:', data);

        if (data.success) {
          setLockers(data.lockers || []);
        } else {
          setError(data.message || data.error);
        }
      } catch (error) {
        setError("An error occurred while fetching this user's lockers.");
        console.error("Error:", error);
      }
    };

    if (user) {
      fetchLockers();
    }
  }, [user]);

  const content = (
    <>
      <div className="navbarBrands">{user ? user.username.charAt(0).toUpperCase() + user.username.slice(1) : 'None'}</div>
      <div>{user ? user.description : 'None'}</div>
    </>
  );

  const breadcrumbs = (
    <div className="breadcrumbs">
      <a href="/home" className="breadcrumb-item">
        Home
      </a>
      <span className="breadcrumb-separator">▶</span>
      <a className="breadcrumb-item" href="/dpi-directory">DPI Directory</a>
      <span className="breadcrumb-separator">▶</span>
      <span className="breadcrumb-item current">TargetUserView</span>
    </div>
  )

  return (
    <div>
      <Navbar content={content} breadcrumbs={breadcrumbs}/>

      <div style={{ marginTop: "120px" }}>
        <div className="heroContainers">
          <div className="newLocker">
            <h3></h3>
            {/* <button id="newLockerBtn" onClick={handleNewLockerClick}>
            Create New Locker
          </button> */}
          </div>
          <div className="page6-allLockers">
            {error && <div className="error">{error}</div>}
            {Array.isArray(allLockers) && allLockers.length > 0 ? (
              allLockers.map(lockers => (
                <div key={lockers.locker_id} className="page6-locker">
                  <h4>{lockers.name}</h4>

                  {lockers.is_frozen === false && <Button id="docsBtn" variant="contained" onClick={() => handleLockersClick(lockers)}>
                    Open
                  </Button>}
                  {lockers.is_frozen === true && <Button id="docsBtn" variant="contained">Frozen</Button>}

                  <p className="description2">{lockers.description}</p>
                </div>
              ))
            ) : (
              <div>No lockers found.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
